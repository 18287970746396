import { Box, chakra } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system/dist/types/system.types";
import { Asset } from "contentful";
import React from "react";
import ReactContentfulImage from "react-contentful-image";

type ChakraImage = ChakraProps &
  Omit<JSX.IntrinsicElements["img"], "sizes" | "src" | "alt">;

export interface ImageRendererProps extends ChakraImage {
  asset?: Asset;
  alt?: string;
  src?: string;
  sizes?: unknown[];
  height?: string;
  width?: string;
  minHeight?: string;
  minWidth?: string;
}

const ChakraReactContentfulImage = chakra(ReactContentfulImage);

export const ImageRenderer: React.FC<ImageRendererProps> = ({
  asset,
  src,
  alt,
  sizes,
  height,
  width,
  maxHeight,
  maxWidth,
  ...props
}) => {
  return (
    <Box
      as="span"
      display="inherit"
      sx={{
        "& > picture": {
          width,
          height,
          maxHeight,
          maxWidth,
        },
      }}
    >
      <ChakraReactContentfulImage
        src={asset?.fields?.file?.url || src}
        alt={asset?.fields?.description || alt}
        sizes={sizes}
        height={height}
        width={width}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        {...props}
      />
    </Box>
  );
};
