import { Entry } from "contentful";
import { GetServerSideProps } from "next";
import Head from "next/head";

import { useRouter } from "next/router";
import Script from "next/script";
import React from "react";
import { Footer } from "../src/components/Footer";
import { Header } from "../src/components/Header";
import { NotFound } from "../src/components/NotFound";
import { getGlobal, getPage } from "../src/contentful";
import { Module } from "../src/modules/Module";
import { initDatadog, InitPageParams } from "../src/tracking";
import {
  GlobalFields,
  PageFields,
  RawPageFields,
  UntranslatedField,
} from "../src/types/contentful";

export interface PageProps {
  global: Entry<GlobalFields>;
  page?: Entry<PageFields>;
  rawPage?: Entry<RawPageFields>;
  preview: boolean;
  appBaseUrl: string;
  locale: string;
  initPageParams: InitPageParams;
}

export default function Page({
  global,
  page,
  rawPage,
  appBaseUrl,
  locale,
}: PageProps) {
  const router = useRouter();

  if (router.isFallback) {
    return <>Loading…</>;
  }

  const addProtocol = (url: string) => {
    if (url.startsWith("//")) {
      return `https:${url}`;
    }

    return url;
  };

  const translatedPageLinks: UntranslatedField<string> = Object.fromEntries(
    global.fields.languages.map((footerLanguage) => [
      footerLanguage.fields.code,
      `${appBaseUrl}/${footerLanguage.fields.code}${
        rawPage?.fields?.path?.[footerLanguage.fields.code] || ""
      }`,
    ])
  );

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        {page && rawPage ? (
          <>
            <title>
              {page.fields.title +
                (page.fields.excludeTitleExtra ? "" : " - Propty")}
            </title>

            <meta name="description" content={page.fields.metaDescription} />

            {!!page.fields.metaKeywords?.length && (
              <meta
                name="keywords"
                content={page.fields.metaKeywords.join(", ")}
              />
            )}

            <link rel="canonical" href={appBaseUrl + rawPage.fields.path.en} />

            {Object.entries(translatedPageLinks).map(([pathLocale, href]) =>
              pathLocale === locale ? null : (
                <link
                  key={`alternate-${pathLocale}`}
                  rel="alternate"
                  hrefLang={pathLocale}
                  href={href}
                />
              )
            )}

            <meta
              property="og:url"
              content={appBaseUrl + rawPage.fields.path.en}
            />
            <meta property="og:title" content={page.fields.title} />
            <meta
              property="og:description"
              content={page.fields.metaDescription}
            />
            {page.fields.metaImage && (
              <meta
                property="og:image"
                content={addProtocol(page.fields.metaImage.fields.file.url)}
              />
            )}
            <meta property="og:locale" content={locale} />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="502523864814818" />
          </>
        ) : (
          <>
            {/* 404 title */}
            <title>Propty</title>
          </>
        )}
      </Head>

      <Header
        fields={global.fields.header.fields}
        locale={locale}
        translatedPageLinks={translatedPageLinks}
        languages={global.fields.languages}
      />

      {page ? (
        page.fields.modules?.map((module, index) => (
          <Module key={`${module.sys.id}-${index}`} module={module} />
        ))
      ) : (
        <>
          {global.fields.notFound && (
            <NotFound fields={global.fields.notFound?.fields} />
          )}
        </>
      )}

      <Footer
        fields={global.fields.footer.fields}
        locale={locale}
        translatedPageLinks={translatedPageLinks}
        languages={global.fields.languages}
      />
      {/* Hubspot tracking script */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/45294418.js"
      ></Script>
    </>
  );
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  params,
  preview = false,
  locale,
  res,
}) => {
  const initPageParams: InitPageParams = {
    datadogApplicationId: process.env.DATADOG_RUM_APPLICATION_ID,
    datadogClientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    service: process.env.SERVICE,
    env: process.env.ENV,
    gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
    gtmParams: process.env.GOOGLE_TAG_MANAGER_PARAMS || "",
  };

  initDatadog(initPageParams);

  const [global, [page, rawPage]] = await Promise.all([
    getGlobal({
      locale,
      preview,
    }),
    getPage({
      path: `/${
        // Home page has no slug
        params.slug === undefined
          ? ""
          : Array.isArray(params.slug)
          ? params.slug.join("/")
          : params.slug
      }`,
      locale,
      preview,
    }),
  ]);

  if (!global) {
    throw new Error("Could not fetch global");
  }

  if (!page || !rawPage) {
    res.statusCode = 404;
  }

  return {
    props: {
      preview,
      locale,
      global,
      page,
      rawPage,
      appBaseUrl: process.env.APP_BASE_URL,
      initPageParams: initPageParams,
    },
  };
};
