/* eslint-disable react/display-name */
import {
  Box,
  BoxProps,
  Divider,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { TypographyProps } from "@chakra-ui/styled-system/dist/types/config/typography";
import { As, ChakraProps } from "@chakra-ui/system/dist/types/system.types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import { RichTextDocument } from "../types/contentful";
import { CmsLink } from "./CmsLink";
import { ImageRenderer } from "./ImageRenderer";

interface RichTextRendererProps {
  text?: RichTextDocument;
  boxProps?: BoxProps;
  textProps?: ChakraProps;
  lineHeight?: TypographyProps["lineHeight"];
  as?: As;
  noPadding?: boolean;
  block?: boolean;
}

export const RichTextRenderer: React.FC<RichTextRendererProps> = ({
  text,
  as,
  boxProps,
  textProps,
  lineHeight,
  noPadding,
  block,
}) => {
  const pb = !block || noPadding ? 0 : 2;

  return (
    <Box lineHeight={lineHeight || 1.5} {...boxProps}>
      {documentToReactComponents(text, {
        renderNode: {
          [BLOCKS.HEADING_1]: (node, children) => (
            <Heading as={as || "h1"} size="4xl" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.HEADING_2]: (node, children) => (
            <Heading as={as || "h2"} size="3xl" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.HEADING_3]: (node, children) => (
            <Heading as={as || "h3"} size="2xl" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.HEADING_4]: (node, children) => (
            <Heading as={as || "h4"} size="xl" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.HEADING_5]: (node, children) => (
            <Heading as={as || "h5"} size="lg" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.HEADING_6]: (node, children) => (
            <Heading as={as || "h6"} size="md" pb={pb} {...textProps}>
              {children}
            </Heading>
          ),
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <Text as={as || "p"} pb={pb} {...textProps}>
              {children}
            </Text>
          ),
          [BLOCKS.UL_LIST]: (node, children) => (
            <UnorderedList>{children}</UnorderedList>
          ),
          [BLOCKS.OL_LIST]: (node, children) => (
            <OrderedList>{children}</OrderedList>
          ),
          [BLOCKS.LIST_ITEM]: (node, children) => (
            <ListItem>{children}</ListItem>
          ),
          [BLOCKS.HR]: () => <Divider my={4} />,
          [BLOCKS.EMBEDDED_ASSET]: (node) => (
            <ImageRenderer asset={node.data.target} />
          ),
          [INLINES.HYPERLINK]: (node, children) => (
            <CmsLink link={node.data.uri}>{children}</CmsLink>
          ),
        },
      })}
    </Box>
  );
};
