import { Box, Flex, VStack } from "@chakra-ui/react";
import { Entry } from "contentful";
import React, { useEffect, useRef } from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";
import { ContactUsItem, ContactUsItemFields } from "./ContactUsItem";

export interface ContactUsModuleFields {
  title?: RichTextDocument;
  hubSpotFormId?: string;
  items?: Entry<ContactUsItemFields>[];
  background?: Entry<BackgroundFields>;
}

interface ContactUsModuleProps {
  fields: ContactUsModuleFields;
}

export const ContactUsModule: React.FC<ContactUsModuleProps> = ({ fields }) => {
  const formRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!fields.hubSpotFormId || !formRef.current) {
      return;
    }

    const hubspotLoadScript = document.createElement("script");
    hubspotLoadScript.type = "text/javascript";

    hubspotLoadScript.src = "//js.hsforms.net/forms/shell.js";

    hubspotLoadScript.onload = () => {
      const hubspotFormScript = document.createElement("script");
      hubspotFormScript.type = "text/javascript";

      hubspotFormScript.innerHTML = `
        hbspt.forms.create({
          region: "na1",
          portalId: "45294418",
          formId: "${fields.hubSpotFormId}"
        });
      `;

      formRef.current.appendChild(hubspotFormScript);
    };

    formRef.current.appendChild(hubspotLoadScript);

    return () => {
      formRef.current.innerHTML = "";
    };
  }, []);

  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          <Flex direction={["column", null, "row", null]}>
            <Box flex={1} mr={[0, null, 8, null]} mb={[8, null, 0, null]}>
              {fields.title && (
                <Box textStyle="title" fontSize="40px" mb={10}>
                  <RichTextRenderer text={fields.title} as="h2" />
                </Box>
              )}

              {!!fields.items?.length && (
                <VStack spacing={10} align="flex-start">
                  {fields.items?.map((item, index) => (
                    <ContactUsItem
                      key={`${item.sys.id}-${index}`}
                      fields={item.fields}
                    />
                  ))}
                </VStack>
              )}
            </Box>

            {fields.hubSpotFormId && (
              <Box flex={1}>
                <div ref={formRef} />
              </Box>
            )}
          </Flex>
        </Box>
      </PageContainer>
    </Background>
  );
};
