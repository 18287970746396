import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

export interface CmsLinkProps extends ChakraLinkProps {
  link?: string;
  openLinkInNewTab?: boolean;
}

export const CmsLink: React.FC<CmsLinkProps> = ({
  link,
  openLinkInNewTab,
  children,
  ...props
}) => {
  const target = openLinkInNewTab ? "_blank" : undefined;

  if (link?.startsWith("/")) {
    return (
      <NextLink href={link} passHref>
        <ChakraLink {...props} target={target}>
          {children}
        </ChakraLink>
      </NextLink>
    );
  }

  return (
    <ChakraLink {...props} target={target} href={link} rel="noopener">
      {children}
    </ChakraLink>
  );
};
